import { Injectable } from '@angular/core';
import { UserI } from '../../../shared/interfaces/user.interface';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class SharedApisService {
  constructor(private readonly apiService: ApiService) {}

  findLoggedInUser() {
    return this.apiService.get<UserI>('users/logged-in-user');
  }

  createOtp(api: string) {
    return this.apiService.post<string>(`otps/${api}`, {});
  }
}
